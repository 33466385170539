import React, {
  useRef,
  useEffect,
  useState,
  useMemo,
  useContext,
  useCallback,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';

import mapboxgl from 'mapbox-gl';
import styles from 'features/map/Map.module.css';

import {
  selectSegmentId,
  setSelectedSegmentId,
  setMapCenter,
  setMapZoom,
  setMaximize,
  selectMaximize,
  selectProject,
  setRouteSelection,
  selectRouteSelection,
  selectCurrentProjectInfo,
  selectMapCenter,
  selectMapZoom,
  selectMapStyle,
} from 'state/workflowSlice';
import { useGetLayerQuery } from 'state/apiSlice';
import { selectUser, selectUserState } from 'state/userSlice';

import { LayerContext } from 'state/LayerContext';

import {
  ToggleLayerControl,
  toggleLayerVisibility,
  enableChartButton,
  ensureRtlLoaded,
  latLngToJsonPoint,
  launderLatLng,
} from 'features/map/mapUtils';
import { MapStyle, MAXIMIZE_MAP } from 'appConstants';

import 'mapbox-gl/dist/mapbox-gl.css';
import 'features/map/Map.css';
import { FloatingLoadingSpinner } from 'features/spinner/Spinner';
import { useSegmentSelectionCrosswalk } from '../workflow_common/SegmentSelectionCrosswalk';
import {
  kSegmentLayerDefinition,
  kSegmentSourceId,
  initMap,
  mapPopSelectedSegment,
  getSelectionFromClickEvent,
  getInitialCenter,
  redrawBaseSegments,
  redrawSelectedSegment,
  showBookmarks,
  safeRemoveBookmarks,
  updateMapLocation,
  kMainVisLineOpacityExpression,
  MapLoadingSpinner,
} from './mapCommon';

import { mapboxApiKey } from '../../appConstants';
import {
  selectDisplayedBookmark,
  selectInLocationEditMode,
  setDisplayedBookmark,
} from '../../state/bookmarkSlices';
import { useCallbackWithErrorHandling } from '../../app/ErrorHandling';
import { MapSettings } from './MapSettings';

mapboxgl.accessToken = mapboxApiKey;

export function Map({
  enableRouteSelection = false,
}: {
  enableRouteSelection?: boolean;
}) {
  // https://docs.mapbox.com/help/tutorials/use-mapbox-gl-js-with-react/

  const dispatch = useDispatch();

  // useRef DOM refs and for variables accessed from map callbacks
  const refMap = useRef<mapboxgl.Map>(null); // mapboxgl.Map object
  const refMapContainer = useRef(null); // DOM node reference
  const refRouting = useRef({});

  const [mapReady, setMapReady] = useState(false);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const { layer } = useContext(LayerContext);
  const selectedSegmentId = useSelector(selectSegmentId);
  const selectedRoute = useSelector(selectRouteSelection);
  const maximize = useSelector(selectMaximize);
  const refMaximize = useRef(maximize);
  const project = useSelector(selectProject);
  const currentMapCenter = useSelector(selectMapCenter);
  const mapZoom = useSelector(selectMapZoom);
  const displayedBookmark = useSelector(selectDisplayedBookmark);
  const inLocationEditMode = useSelector(selectInLocationEditMode);
  const refInLocationEditMode = useRef(inLocationEditMode);
  const mapStyle = useSelector(selectMapStyle);
  const userInfo = useSelector(selectUserState);

  const refSelection = useRef({
    segment: selectedSegmentId,
    route: selectedRoute,
  });

  const user = useSelector(selectUser);
  const userProject = useSelector(selectCurrentProjectInfo);

  // load segmentData for layer from REST api (or cache)
  const { currentData: layerData } = useGetLayerQuery(layer, { skip: !layer });
  useSegmentSelectionCrosswalk();

  // memoize copy of segments
  refRouting.current = useMemo(() => layerData?.routing, [layerData]);

  const carLayerVisible = true;

  ensureRtlLoaded();

  const handleSegmentClickCallback = useCallbackWithErrorHandling((event) => {
    // console.log(`handleSegmentClickCallback ${JSON.stringify(event.lngLat)} refSegments.current ${JSON.stringify(refSegments.current.length)}`);
    if (refInLocationEditMode.current) {
      dispatch(setDisplayedBookmark(launderLatLng(event.lngLat)));
    } else {
      const {
        segmentId: newSelectedSegmentId,
        segment: selectedSegment,
        route,
      } = getSelectionFromClickEvent(
        event,
        refMap.current,
        refRouting,
        enableRouteSelection,
      );
      if (route) {
        dispatch(setRouteSelection(route));
      } else if (route === null) {
        dispatch(setRouteSelection(undefined));
      }
      console.log('Selection = ', newSelectedSegmentId);
      dispatch(setSelectedSegmentId(newSelectedSegmentId));
    }
  });

  const handleMapMoveCallback = useCallbackWithErrorHandling(() => {
    const center = {
      lng: refMap.current.getCenter().lng.toFixed(4),
      lat: refMap.current.getCenter().lat.toFixed(4),
    };
    const newZoom = refMap.current.getZoom().toFixed(2);
    dispatch(setMapZoom(newZoom));
    dispatch(setMapCenter(center));
  });

  function handleMapLoadCallback() {
    setMapReady(true);
  }

  const handleChartShowHideClickCallback = () => {
    dispatch(setMaximize(refMaximize.current ? null : MAXIMIZE_MAP));
  };

  function dispatchSelectionRedraw(force = false) {
    if (refMap.current) {
      if (!carLayerVisible) {
        mapPopSelectedSegment(refMap.current);
      } else {
        redrawSelectedSegment(
          mapReady,
          refMap.current,
          refSelection.current.segment,
          refRouting.current,
          refSelection.current.route,
          force,
        );
      }
    }
  }

  const handleSourceDataEvent = useCallbackWithErrorHandling(
    (e: mapboxgl.MapSourceDataEvent) => {
      // Given we now rely on the segments being loaded via tiles to render
      // Selected and routes, we have to listen to new tiles being rendered
      // In case the selected segments or any of the route segments are suddenly in view
      if (e.sourceId === kSegmentSourceId) {
        dispatchSelectionRedraw();
      }
    },
  );

  const handleMapSettingsClickCallback = (e) => {
    setSettingsOpen(true);
  };

  useEffect(() => {
    if (refMap.current) {
      refMap.current.remove();
      refMap.current = undefined;
      setMapReady(false);
    }
    if (!refMap.current) {
      const { center, zoom } = getInitialCenter(userProject);
      if (center) {
        initMap(
          refMap,
          refMapContainer,
          center,
          zoom,
          mapStyle,
          userInfo.has_hidden_features,
          handleMapLoadCallback,
          handleMapMoveCallback,
          handleSegmentClickCallback,
          handleMapSettingsClickCallback,
        );
        refMap.current.addControl(
          new ToggleLayerControl(
            'chart-showhide',
            'Hide/show chart',
            true,
            handleChartShowHideClickCallback,
          ),
        );
        refMap.current.on('sourcedata', handleSourceDataEvent);
      } else {
        console.log(
          'Project defaults not yet loaded, skipping initialisation of map',
        );
      }
    }
  }, [userProject, mapStyle]); // eslint-disable-line react-hooks/exhaustive-deps

  // redrawSegments
  useEffect(
    () => {
      // console.log(`============= useeffect redrawSegments (mapReady: ${mapReady} layerData ${refSegments.current.length}`);
      const layerDefinition = { ...kSegmentLayerDefinition };
      layerDefinition.paint = { ...layerDefinition.paint };
      layerDefinition.paint['line-opacity'] = kMainVisLineOpacityExpression(
        { [MapStyle.Dark]: 0.7, [MapStyle.Sat]: 1.0 }[mapStyle] || 0.7,
      );
      redrawBaseSegments(
        mapReady,
        refMap.current,
        layerDefinition,
        layer ? `server/tiles/${layer}` : undefined,
      );
      // Redraw selected segments on layer change!
      dispatchSelectionRedraw(true);
    },
    // note - we specify layerData as dependency because change to mutable refSegments doesn't rerender
    // we don't need dependency on selectedSegmentId even though we draw the selected statement
    // because (besides initial render) it only erasing/drawing it is handled by handleSegmentClickCallback
    [mapReady, layerData], // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    refSelection.current.segment = selectedSegmentId;
    refSelection.current.route = selectedRoute;
    dispatchSelectionRedraw();
  }, [selectedRoute, selectedSegmentId]);

  useEffect(() => {
    refMaximize.current = maximize;
    if (refMap.current) {
      refMap.current.resize();
    }
  }, [mapReady, maximize]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (refMap.current) {
      const { center } = getInitialCenter(userProject);
      refMap.current.jumpTo({ center });
    }
  }, [project]);

  useEffect(() => {
    updateMapLocation(refMap.current, mapZoom, currentMapCenter);
  }, [currentMapCenter, mapZoom]);

  useEffect(() => {
    refInLocationEditMode.current = inLocationEditMode;
    if (mapReady) {
      if (inLocationEditMode) {
        if (!displayedBookmark) {
          dispatch(setDisplayedBookmark({ ...refMap.current.getCenter() }));
        } else {
          const bookmarks = {
            type: 'FeatureCollection',
            features: [
              {
                type: 'Feature',
                geometry: latLngToJsonPoint(displayedBookmark),
              },
            ],
          };
          showBookmarks(refMap.current, bookmarks);
        }
      } else {
        safeRemoveBookmarks(refMap.current);
      }
    }
  }, [mapReady, dispatch, inLocationEditMode, displayedBookmark]);

  return (
    <div ref={refMapContainer} className={styles.map}>
      <MapLoadingSpinner currentRefMap={refMap} mapReady={mapReady} />
      <MapSettings
        open={settingsOpen}
        handleVisSwitch={(visible) => setSettingsOpen(visible)}
      />
    </div>
  );
}
