import dayjs from 'dayjs';

import { SpeedData, XyArray } from 'appTypes';

import { convertSpeeds } from '../common/utils';
import { graph_highlight_color } from './chartOptions';

// Correct way of handling the timezones, however highcharts isn't obeying my timezone proclamation
// import utc from 'dayjs/plugin/utc';
// import timezone from 'dayjs/plugin/timezone';
// import customParseFormat from 'dayjs/plugin/customParseFormat';
// dayjs.extend(utc);
// dayjs.extend(timezone);
// dayjs.extend(customParseFormat);
// function chartStart(YYYY_MM_DD: string) {
//   return dayjs.tz(YYYY_MM_DD, 'YYYY-MM-DD', timezoneNY).subtract(3, 'hour');
// }
// function chartEnd(YYYY_MM_DD: string) {
//   return dayjs(YYYY_MM_DD, 'YYYY-MM-DD')
//     .tz(timezoneNY, true).add(1, 'day').add(3, 'hour');
// }
// function parse_ts(dt: string) {
//   return dayjs(dt).tz(timezoneNY, false).valueOf();
// }

export function parseDate(YYYY_MM_DD: string) {
  return dayjs(YYYY_MM_DD, 'YYYY-MM-DD');
}

export function chartStart(YYYY_MM_DD: string) {
  return parseDate(YYYY_MM_DD).subtract(3, 'hour');
}

export function chartEnd(YYYY_MM_DD: string) {
  return parseDate(YYYY_MM_DD).add(1, 'day').add(3, 'hour');
}

export function parse_ts(dt: string) {
  dt = dt.replace(/[-+][0-9]{1,2}:[0-9]{1,2}$/i, '');
  const dt_t = dayjs(dt);
  return dt_t.valueOf();
}

// export function toChartableSpeeds(segmentSpeeds: SpeedData) {
//   let bins = [];
//   if (segmentSpeeds) {
//     bins = segmentSpeeds.speeds.map((row) => ({ x: Date.parse(row[0]), y: row[2] }));
//     for (let i = 0; i < bins.length; i++) {
//       const enabled = (i === 0 || bins[i - 1].y === null) && (i === bins.length - 1 || bins[i + 1].y === null);
//       bins[i].marker = { enabled };
//     }
//   }
//   return bins;
// }

export function toIndex(timestamp: number, firstBin, lastBin, binTicksMs) {
  // return ordinal bin index for specified timestamp (must be on a bin boundary)
  console.assert(timestamp >= firstBin, timestamp);
  console.assert(timestamp <= lastBin, timestamp);
  const i = (timestamp - firstBin) / binTicksMs;
  console.assert(i % 1 === 0);
  return Math.floor(i);
}

export function sparseToDense(
  sparseArray,
  firstBin,
  lastBin,
  binTicksMs,
  offsetTo,
): XyArray {
  const numBins = 1 + (lastBin - firstBin) / binTicksMs;

  // console.log(`numBins ${numBins} with ticks as ${binTicksMs / 1000}`);

  const dense = Array(numBins);
  for (let i = 0; i < numBins; i += 1) {
    const b = firstBin + i * binTicksMs;
    dense[i] = { x: b, y: null };
  }

  const sparseMetrics = sparseArray.map((row) => {
    // console.log(row[0], dayjs(row[0]).tz(timezoneNY, true), parse_ts(row[0]));
    return { x: parse_ts(row[0]), y: row[1] };
  });
  // console.log(sparseMetrics);
  for (let i = 0; i < sparseMetrics.length; i += 1) {
    const { x, y } = sparseMetrics[i];
    const index = toIndex(x, firstBin, lastBin, binTicksMs);
    if (index >= 0 && index < dense.length) {
      dense[index].y = y;
    }
  }

  for (let i = 0; i < dense.length; i++) {
    // marker for isolated points  (replace && with || below to also put markers at start and end of segments)
    const enabled =
      (i === 0 || dense[i - 1].y === null) &&
      (i === dense.length - 1 || dense[i + 1].y === null);
    dense[i].marker = { enabled };
    dense[i].x += offsetTo - firstBin;
  }

  return dense;
}

export class ChartDataExpander {
  targetDate: string;
  firstBin: number;
  lastBin: number;
  numBins: number;
  binTicksMs: number;
  offsetTo: number;

  constructor(targetDate: string, offsetTo?: string) {
    console.assert(targetDate);
    this.targetDate = targetDate;
    // console.log(chartStart(targetDate), chartEnd(targetDate));
    this.firstBin = chartStart(targetDate).valueOf();
    this.lastBin = chartEnd(targetDate).valueOf();
    this.offsetTo = offsetTo ? chartStart(offsetTo).valueOf() : this.firstBin;
    // console.log(`firstBin ${this.firstBin} lastBin ${this.lastBin}`);
  }

  expandSpeeds(rawSpeeds: SpeedData, uses_metric: boolean): XyArray {
    // extract and backfill sparse SpeedData array with null datapoints for all time bins
    // Infer bin size from first row bin size
    if (rawSpeeds) {
      console.assert(rawSpeeds.bin_size !== undefined, rawSpeeds);
      this.binTicksMs = rawSpeeds.bin_size * 60 * 1000;
    } else {
      return undefined;
    }
    if (rawSpeeds?.speeds) {
      const denseSpeeds = sparseToDense(
        rawSpeeds.speeds,
        this.firstBin,
        this.lastBin,
        this.binTicksMs,
        this.offsetTo,
      );
      if (uses_metric) {
        for (let i = 0; i < denseSpeeds.length; i++) {
          denseSpeeds[i].y = convertSpeeds(denseSpeeds[i].y, uses_metric);
        }
      }
      return denseSpeeds;
    }
    return undefined;
  }

  slowdownBands(
    observed: XyArray,
    typical: XyArray,
    slowdownThreshold: number,
    highlight_higher = false,
  ) {
    // return plotBand array with a band for each interval where observed speed is below slowdownThreshold of typical
    const plotBands = Array(0);

    if (observed && typical && slowdownThreshold) {
      let fromX = null;
      let toX = null;
      for (let i = 0; i < observed.length; i += 1) {
        const { x, y: yObserved } = observed[i];
        const { x: xTypical, y: yTypical } = typical[i];

        console.assert(x === xTypical, 'x !== xTypical');
        let isBelow;
        if (yTypical && yObserved) {
          if (highlight_higher) {
            isBelow = yObserved > yTypical * slowdownThreshold;
          } else {
            isBelow = yObserved < yTypical * slowdownThreshold;
          }
        }

        if (isBelow) {
          if (!fromX) {
            // entering band
            fromX = x;
            // console.log(`enter band at ${x}`);
          }
          toX = x;
        } else {
          if (fromX) {
            // leaving band
            const raw_from = fromX;
            const raw_to = toX + this.binTicksMs;
            fromX -= this.binTicksMs / 2; // expand band to bin midpoint before and after
            toX += this.binTicksMs / 2;
            plotBands.push({
              color: graph_highlight_color,
              opacity: 0.5,
              from: fromX,
              raw_from,
              to: toX,
              raw_to,
            });
            // console.log(`leaving band at ${x}`);
          }
          fromX = null;
          toX = null;
        }
      }

      if (fromX) {
        fromX -= this.binTicksMs / 2;
        plotBands.push({ color: graph_highlight_color, from: fromX, to: toX });
      }
    }
    return plotBands;
  }

  flatline(y: number): XyArray {
    // return 2-element flatline array with datapoints at first and last time bin
    const bins = [];
    bins.push({ x: this.firstBin, y });
    bins.push({ x: this.lastBin, y });
    return bins;
  }
}
