/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import dayjs, { Dayjs } from 'dayjs';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay';
import { LocalizationProvider } from '@mui/x-date-pickers';

import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { alpha } from '@mui/material';

import { useGetDatesQuery } from 'state/apiSlice';

import {
  setTargetDate,
  selectTargetDate,
  selectProject,
} from 'state/workflowSlice';

import LoadingSpinner from 'features/spinner/Spinner';

import styles from 'features/task_bar/Taskbar.module.css';
import { stl_gold_color_light, stl_realtime_color_light } from 'theme/cemTheme';
import 'features/task_bar/DatePicker.css';
import { OverflowLabel } from './OverflowLabel';
import { parseDate } from '../chart/ChartDataExpander';
import { DatesList, DayInfo } from '../../appTypes';
import { TaskBarComponent } from './TaskBar';

export function findDate(targetDate_, datesInfo_): DayInfo | undefined {
  // Pretty horrible reverse seach for the selected day, this is a bit like doing .format() but probably worse
  for (let i = 0; i < datesInfo_.length; i++) {
    if (dayjs(datesInfo_[i].date).isSame(targetDate_)) {
      return datesInfo_[i];
    }
  }
  return undefined;
}

export function findRTDate(datesInfo_: DatesList): DayInfo | undefined {
  for (let i = datesInfo_.length - 1; i >= 0; i--) {
    if (!datesInfo_[i].is_complete_vnrt && !datesInfo_[i].is_nrt) {
      return datesInfo_[i];
    }
  }
  return undefined;
}

export function useDayInfo() {
  const project = useSelector(selectProject);
  const targetDate = useSelector(selectTargetDate);
  const { currentData: datesInfo, isSuccess } = useGetDatesQuery(project, { skip: !project });
  let day_info;
  if (targetDate && datesInfo) {
    day_info = findDate(targetDate, datesInfo);
  }
  return { day_info };
}

function CustomDay(
  props: PickersDayProps<Dayjs> & {
    vnrtOrRtDates: string[];
    nrtDates: string[];
  },
) {
  const { vnrtOrRtDates, nrtDates, day, outsideCurrentMonth, ...other } = props;

  const isVnrtOrRt =
    !outsideCurrentMonth &&
    vnrtOrRtDates.indexOf(day.format('YYYY-MM-DD')) >= 0;
  const isNrt =
    !outsideCurrentMonth && nrtDates.indexOf(day.format('YYYY-MM-DD')) >= 0;

  function routeToStyle() {
    const BORDER_WIDTH = 2;
    const BORDER_STYLE = 'solid';
    const HOVER_ALPHA = 0.7;

    if (isVnrtOrRt) {
      return {
        '&.MuiPickersDay-root': {
          '&:hover': {
            backgroundColor: alpha(stl_realtime_color_light, HOVER_ALPHA),
            color: 'black',
          },
          '&.Mui-selected': {
            backgroundColor: stl_realtime_color_light,
          },
          borderColor: stl_realtime_color_light,
          borderWidth: BORDER_WIDTH,
          borderStyle: BORDER_STYLE,
        },
      };
    }

    if (isNrt) {
      return {
        '&.MuiPickersDay-root': {
          '&:hover': {
            backgroundColor: alpha(stl_gold_color_light, HOVER_ALPHA),
            color: 'black',
          },
          '&.Mui-selected': {
            backgroundColor: stl_gold_color_light,
          },
          borderColor: stl_gold_color_light,
          borderWidth: BORDER_WIDTH,
          borderStyle: BORDER_STYLE,
        },
      };
    }

    return {};
  }
  return (
    <PickersDay
      {...other}
      outsideCurrentMonth={outsideCurrentMonth}
      day={day}
      sx={routeToStyle()}
    />
  );
}

export function GenericNRTDatePicker({
  enableDayArrows = false,
  targetDate,
  setNewTargetDate,
  fullWidth = false,
  forbiddenDates = [],
}: {
  enableDayArrows?: boolean;
  targetDate: string;
  setNewTargetDate: (value: string | undefined) => void;
  fullWidth?: boolean;
  forbiddenDates?: string[];
}) {
  // datesList from REST api
  const project_slug = useSelector(selectProject);
  const {
    data: rawDatesInfo,
    isLoading,
    isFetching,
    isError,
  } = useGetDatesQuery(
    project_slug,
    { skip: !project_slug, pollingInterval: 3 * 60 * 60 * 1000 }, // every 3 hours
  );

  // This is used for coloring only, and we want to include the "forbidden" dates
  const vnrtOrRtDates =
    rawDatesInfo &&
    rawDatesInfo
      .filter((dateInfo) => !dateInfo.is_nrt)
      .map((dateInfo) => dateInfo.date);
  const nrtDates =
    rawDatesInfo &&
    rawDatesInfo
      .filter((dateInfo) => dateInfo.is_nrt)
      .map((dateInfo) => dateInfo.date);

  // Filter out forbidden dates
  const datesInfo =
    rawDatesInfo &&
    rawDatesInfo.filter((info) => !(forbiddenDates.indexOf(info.date) !== -1));

  // memoize list of dates as strings
  const datesList = useMemo(
    () => (datesInfo ? datesInfo.map((info) => dayjs(info.date)) : []),
    [datesInfo],
  );

  const handleChange = (newTargetDate) => {
    const foundDate = findDate(newTargetDate, datesInfo);
    if (foundDate) {
      setNewTargetDate(foundDate.date);
    }
  };

  useEffect(() => {
    if (datesInfo && datesInfo.length > 0) {
      if (!targetDate) {
        console.log('DatePicker setting empty targetDate to first date');
        setNewTargetDate(datesInfo[datesInfo.length - 1].date);
      } else if (!findDate(dayjs(targetDate), datesInfo)) {
        console.log('DatePicker setting targetDate not available');
        setNewTargetDate(datesInfo[datesInfo.length - 1].date);
      }
    }
  }, [datesInfo, targetDate]);

  const searchDate = (date) => {
    for (let i = 0; i < datesList.length; i++) {
      if (datesList[i].isSame(date)) {
        return true;
      }
    }
    return false;
  };

  const searchMonth = (date: dayjs.Dayjs) => {
    for (let i = 0; i < datesList.length; i++) {
      if (
        datesList[i].year() === date.year() &&
        datesList[i].month() === date.month()
      ) {
        return true;
      }
    }
    return false;
  };

  const searchYear = (date: dayjs.Dayjs) => {
    for (let i = 0; i < datesList.length; i++) {
      if (datesList[i].year() === date.year()) {
        return true;
      }
    }
    return false;
  };

  let nextDay;
  let lastDay;
  let nextDayAvailable = false;
  let lastDayAvailable = false;

  if (targetDate && datesInfo) {
    nextDay = parseDate(targetDate).add(1, 'day').format('YYYY-MM-DD');
    lastDay = parseDate(targetDate).subtract(1, 'day').format('YYYY-MM-DD');
    for (let i = 0; i < datesInfo.length; i++) {
      if (nextDay === datesInfo[i].date) {
        nextDayAvailable = true;
      }
      if (lastDay === datesInfo[i].date) {
        lastDayAvailable = true;
      }
    }
  }

  let minDate;
  if (datesInfo) {
    for (let i = 0; i < datesList.length; i++) {
      if (!minDate || datesList[i] < minDate) {
        minDate = datesList[i];
      }
    }
  }

  const goOnADay = (event) => {
    setNewTargetDate(nextDay);
  };

  const goBackADay = (event) => {
    setNewTargetDate(lastDay);
  };

  let content;

  if (isLoading || isFetching) {
    content = <LoadingSpinner />;
    content = 'loading available dates...';
  } else if (isError) {
    content = <span>Could not load dates.</span>;
  } else if (datesList) {
    content = (
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en">
        <DatePicker
          className={
            fullWidth
              ? styles.task_bar_date_picker_full_width
              : styles.task_bar_date_picker
          }
          value={targetDate ? dayjs(targetDate) : null}
          minDate={minDate}
          shouldDisableDate={(date) => {
            return !searchDate(date);
          }}
          shouldDisableMonth={(month) => {
            return !searchMonth(month);
          }}
          shouldDisableYear={(year) => {
            return !searchYear(year);
          }}
          disableFuture
          onChange={handleChange}
          slots={{
            day: CustomDay,
          }}
          slotProps={{
            day: {
              vnrtOrRtDates,
              nrtDates,
            } as any,
          }}
        />
      </LocalizationProvider>
    );
    if (enableDayArrows) {
      content = (
        <Grid
          container
          spacing={0}
          alignItems="center"
          className={styles.task_bar_date_picker_arrow_grid}
        >
          <Grid item>
            <Tooltip title="Previous day">
              <span>
                <IconButton
                  size="small"
                  onClick={goBackADay}
                  disabled={!lastDayAvailable}
                  className={styles.dayChangeButton}
                >
                  <ArrowLeftIcon />
                </IconButton>
              </span>
            </Tooltip>
          </Grid>
          <Grid item xs>
            {content}
          </Grid>
          <Grid item>
            <Tooltip title="Next day">
              <span>
                <IconButton
                  size="small"
                  onClick={goOnADay}
                  disabled={!nextDayAvailable}
                  className={styles.dayChangeButton}
                >
                  <ArrowRightIcon />
                </IconButton>
              </span>
            </Tooltip>
          </Grid>
        </Grid>
      );
    }
  }

  return content;
}

export function NRTDatePicker({
  enableDayArrows = false,
}: {
  enableDayArrows?: boolean;
}) {
  const targetDate = useSelector(selectTargetDate);
  const dispatch = useDispatch();
  return (
    <TaskBarComponent>
      <OverflowLabel id="date-picker-input-label">
        Date: &nbsp;
        <GenericNRTDatePicker
          enableDayArrows={enableDayArrows}
          targetDate={targetDate}
          setNewTargetDate={(value) => {
            dispatch(setTargetDate(value));
          }}
        />
      </OverflowLabel>
    </TaskBarComponent>
  );
}
