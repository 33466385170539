import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { WorkflowPlanning } from 'features/workflow_planning/WorkflowPlanning';
import { WorkflowSpeed } from 'features/workflow_nrt/WorkflowSpeed';

import {
  WORKFLOW_PLAN,
  WORKFLOW_TRAVEL_TIME,
  WORKFLOW_SPEED,
  WORKFLOW_TIMELINE,
} from 'appConstants';

import { selectWorkflow } from 'state/workflowSlice';
import { WorkflowTimeline } from 'features/workflow_timeline/WorkflowTimeline';
import { WorkflowTravelTime } from 'features/workflow_traveltime/WorkflowTravelTime';
import { selectUserStatus } from '../../state/userSlice';
import { dispatchUserStatus } from '../../app/AppDispatcher';

export function Workspace() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userStatus = useSelector(selectUserStatus);
  const workflow = useSelector(selectWorkflow);

  // console.log(`Login userStatus: ${userStatus}`);

  useEffect(() => {
    // Check if user is already loggedIn
    dispatchUserStatus(userStatus, dispatch, navigate);
  }, [userStatus]);

  /* eslint-disable */
  switch (workflow) {
    case WORKFLOW_PLAN:
      return <WorkflowPlanning />;
    case WORKFLOW_TRAVEL_TIME:
      return <WorkflowTravelTime />;
    case WORKFLOW_SPEED:
      return <WorkflowSpeed />;
    case WORKFLOW_TIMELINE:
      return <WorkflowTimeline />;
    default:
      return null;
  }
  /* eslint-enable */
}
