import React, { RefObject, useEffect, useState, ReactElement } from 'react';
import mapboxgl from 'mapbox-gl';
import MapboxLanguage from '@mapbox/mapbox-gl-language';

import { BottomLeftFloatingLoadingSpinner } from 'features/spinner/Spinner';
import {
  kBookmarkMarkerImg,
  kDirectionArrowName,
  kHalfDirectionArrowNameLHD,
  kHalfDirectionArrowNameRHD,
} from './MapIconProvider';
import {
  findNearestSegmentOnMap,
  geojsonCoordinates,
  geojsonToCoordinates,
  getRouteCost,
  getRouteForSparseSegments,
  getRoutingForSegmentPair,
  makeSnakeFromIDs,
  mergeSegments,
  SettingsControl,
} from './mapUtils';
import { isMobileOrTablet } from '../../appUtils';
import { LayerRouting, PtLatLng } from '../../appTypes';
import {
  getProjectState,
  getWorkflowState,
  kDefaultMapStyle,
} from '../../state/workflowSlice';
import { getState, store } from '../../state/store';
import { deepEquals } from '../common/utils';
import { stl_blue_color } from '../../theme/cemTheme';
import { MapStyle } from '../../appConstants';

export const MIN_ZOOM = 5;
export const MAX_ZOOM = 18;
export const initZoom = 12;
export const kSelectedSegmentLayerId = 'selection';
export const kSelectedSegmentSymbolsLayerId = 'selectionSymbols';
export const kSegmentSymbolsLayerId = 'segmentSymbols';
export const kSelectedRouteLayerId = 'route';
export const kSegmentLayerId = 'segments';
export const kSegmentSourceId = 'segments_source';
export const kSegmentHoverId = 'segments_hover';
export const kSegmentLayerSourceLayerId = 'segments'; // Matches kSegmentLayerId
export const kPedSegmentLayerId = 'ped_segments';
export const kBookmarkLayerId = 'bookmarks';

export const selectedSegmentColor = '#FFFFFF';
// export const selectedRouteColor = '#f0cf7f';
export const selectedRouteColor = stl_blue_color;
export const baseSegmentColor = '#FFDA6A';

export const kMaxScreenDistanceForSelect = 40;
export const kMaxScreenDistanceForRouteSelect = 200;

// export const mapBoxStyleKey = 'mapbox://styles/pfriedman/clpvhc0cn003o01qm6iom9820';
const MAP_STYLE_LOOKUP = {
  [MapStyle.Dark]: 'mapbox://styles/stlgbartonowen/cltq4maic00ku01p54c0f1127',
  [MapStyle.Sat]: 'mapbox://styles/stlgbartonowen/clwshetae01lt01qefzjdc994',
};

export const thick_line_width_expression = [
  'interpolate',
  ['linear'],
  ['zoom'],
  8,
  3,
  14,
  6,
  16,
  10,
  19,
  20,
];

export const line_width_expression = [
  'interpolate',
  ['linear'],
  ['zoom'],
  8,
  1,
  14,
  3,
  16,
  8,
  19,
  20,
];

export const icon_size_expression = [
  'interpolate',
  ['linear'],
  ['zoom'],
  8,
  0.4,
  14,
  0.4,
  16,
  0.8,
  19,
  1,
];

export const kMainVisLineOpacity = 0.7;
export const kMainVisLineOpacityExpression = (baseOpacity) => [
  'interpolate',
  ['linear'],
  ['get', 'road_class'],
  0,
  baseOpacity,
  1,
  baseOpacity,
  5,
  0.2,
];
export const kMainVisLineOpacitySegmentSelected = 0.45;

export const kMainVisLineOpacitySegmentSelectedExpression = [
  'interpolate',
  ['linear'],
  ['get', 'road_class'],
  0,
  kMainVisLineOpacitySegmentSelected,
  1,
  kMainVisLineOpacitySegmentSelected,
  5,
  0.2,
];

export const kSelectedSegmentLayerDefinition = {
  id: kSelectedSegmentLayerId,
  type: 'line',
  source: kSelectedSegmentLayerId,
  layout: {
    'line-cap': 'round',
  },
  paint: {
    'line-color': selectedSegmentColor,
    // 'line-color': 'red',
    'line-width': ['interpolate', ['linear'], ['zoom'], 7, 15, 15, 10],
  },
};

export const kSelectedSegmentLayerArrowDefinition = {
  id: kSelectedSegmentSymbolsLayerId,
  type: 'symbol',
  source: kSelectedSegmentLayerId,
  layout: {
    'symbol-placement': 'line-center',
    'icon-image': kDirectionArrowName,
    'icon-rotate': 90,
    'icon-rotation-alignment': 'map',
    'icon-allow-overlap': true,
    'icon-ignore-placement': true,
    'icon-size': icon_size_expression as any,
  },
  paint: {
    'icon-color': selectedSegmentColor,
    'icon-opacity': 1,
  },
};

export const kSegmentLayerArrowDefinition: mapboxgl.SymbolLayer = {
  id: kSegmentSymbolsLayerId,
  type: 'symbol',
  source: kSegmentSourceId,
  'source-layer': kSegmentLayerSourceLayerId,
  layout: {
    'symbol-placement': 'line-center',
    'icon-image': kHalfDirectionArrowNameRHD,
    'icon-rotate': 90,
    'icon-rotation-alignment': 'map',
    'icon-allow-overlap': false,
    'icon-ignore-placement': true,
    'icon-size': icon_size_expression as any,
  },
};

export const kSelectedRouteLayerDefinition = {
  id: kSelectedRouteLayerId,
  type: 'line',
  source: kSelectedRouteLayerId,
  layout: {
    'line-cap': 'round',
  },
  paint: {
    'line-color': selectedRouteColor,
    // 'line-color': 'red',
    'line-width': ['interpolate', ['linear'], ['zoom'], 7, 15, 15, 10],
  },
};

export const kSegmentLayerDefinition = {
  id: kSegmentLayerId,
  type: 'line',
  source: kSegmentSourceId,
  layout: {},
  paint: {
    'line-color': baseSegmentColor,
    'line-width': line_width_expression,
    'line-opacity': kMainVisLineOpacityExpression(kMainVisLineOpacity),
  },
};

export const kBookmarkLayerDefinition: mapboxgl.SymbolLayer = {
  id: kBookmarkLayerId,
  type: 'symbol',
  source: kBookmarkLayerId,
  layout: {
    'icon-image': kBookmarkMarkerImg,
    'icon-allow-overlap': false,
    'icon-size': 2,
  },
  paint: {
    'icon-color': stl_blue_color,
    'icon-opacity': 1,
  },
};

export function mapStyleToUrl(mapStyle) {
  return MAP_STYLE_LOOKUP[mapStyle || kDefaultMapStyle];
}

export function loadUsedImages(map) {
  function loadImageWhenReady(name: any, element: any, sdf: boolean = false) {
    const options: any = {};
    if (sdf) {
      options.sdf = true;
    }
    if (element.complete) {
      if (map.hasImage(name)) map.removeImage(name);
      map.addImage(name, element, options);
    } else {
      element.onload = () => {
        if (map.hasImage(name)) map.removeImage(name);
        map.addImage(name, element, options);
      };
    }
  }
  [
    kHalfDirectionArrowNameLHD,
    kHalfDirectionArrowNameRHD,
    kBookmarkMarkerImg,
    kDirectionArrowName,
  ].map((item) => {
    const el = document.querySelector(`#${item}`) as any;
    loadImageWhenReady(item, el, true);
    return item;
  });
}

export function initMap(
  refMap,
  refMapContainer,
  center,
  zoom,
  mapStyle,
  showChartSettings,
  handleMapLoadCallback,
  handleMapMoveCallback,
  handleSegmentClickCallback,
  handleMapSettingsClickCallback,
) {
  const style_url = mapStyleToUrl(mapStyle);
  const map = new mapboxgl.Map({
    container: refMapContainer.current,
    style: style_url,
    center: [center.lng, center.lat],
    zoom,
    minZoom: MIN_ZOOM,
    maxZoom: MAX_ZOOM,
    dragPan: !isMobileOrTablet(),
    attributionControl: false,
  });
  refMap.current = map;
  // map.showTileBoundaries = true;
  map.dragRotate.disable();
  map.touchZoomRotate.disableRotation();
  map.addControl(new mapboxgl.AttributionControl(), 'top-right');

  map.addControl(
    new mapboxgl.NavigationControl({
      showCompass: false,
    }),
  );

  map.addControl(
    new MapboxLanguage({
      defaultLanguage: 'mul',
    }),
  );
  if (showChartSettings) {
    const settingsControlId = 'map-settings-control';
    map.addControl(
      new SettingsControl(settingsControlId, handleMapSettingsClickCallback),
    );
  }
  loadUsedImages(map);
  map.on('load', handleMapLoadCallback);
  map.on('moveend', handleMapMoveCallback);
  map.on('click', handleSegmentClickCallback);

  // document.querySelector<HTMLElement>('#chart-showhide').onclick = handleChartShowHideClickCallback;
}

export function mapHasLayer(map: mapboxgl.Map, layerId) {
  return map.getLayer(layerId) !== undefined;
}

export function mapLayerSafeRemove(map, layerId) {
  if (mapHasLayer(map, layerId)) {
    map.removeLayer(layerId);
  }
}

export function mapSourceSafeRemove(map, sourceId) {
  if (map.getSource(sourceId) !== undefined) {
    map.removeSource(sourceId);
  } else {
    console.debug('Source does not exist', sourceId);
  }
}

export function mapShowingSelectedSegment(
  map: mapboxgl.Map,
  checkLoaded = false,
) {
  return (
    mapHasLayer(map, kSelectedSegmentLayerId) &&
    (!checkLoaded || (map.getSource(kSelectedSegmentLayerId) as any)._loaded) // eslint-disable-line
  );
}

export function mapShowingSegments(map) {
  return mapHasLayer(map, kSegmentLayerId);
}

export function mapShowingRouteSegments(map) {
  return mapHasLayer(map, kSelectedRouteLayerId);
}

export function mapPushSelectedSegment(map, segment) {
  // console.assert(!mapShowingSelectedSegment(map));
  if (!mapShowingSelectedSegment(map)) {
    // console.log('Drawing selected segment', segment);
    map.addSource(kSelectedSegmentLayerId, {
      type: 'geojson',
      data: segment,
    });
    map.addLayer(kSelectedSegmentLayerDefinition);
    map.addLayer(kSelectedSegmentLayerArrowDefinition);
    if (mapShowingSegments(map)) {
      map.setPaintProperty(
        kSegmentLayerId,
        'line-opacity',
        kMainVisLineOpacitySegmentSelectedExpression,
      );
    }
    console.assert(mapShowingSelectedSegment(map));
  }
}

export function mapPushRouteSelect(route, map) {
  // eslint-disable-next-line
  if (!mapShowingRouteSegments(map) && map.style._loaded) {
    if (route) {
      map.addSource(kSelectedRouteLayerId, {
        type: 'geojson',
        data: route,
      });
      map.addLayer(
        kSelectedRouteLayerDefinition,
        mapShowingSelectedSegment(map) ? kSelectedSegmentLayerId : undefined,
      );
    }
  }
}

export function mapPopSelectedSegment(map) {
  console.assert(mapShowingSelectedSegment(map));
  mapLayerSafeRemove(map, kSelectedSegmentLayerId);
  mapLayerSafeRemove(map, kSelectedSegmentSymbolsLayerId);
  mapSourceSafeRemove(map, kSelectedSegmentLayerId);
  if (mapShowingSegments(map)) {
    map.setPaintProperty(
      kSegmentLayerId,
      'line-opacity',
      kMainVisLineOpacityExpression(kMainVisLineOpacity),
    );
  }
  console.assert(!mapShowingSelectedSegment(map));
}

export function mapPopRouteSegments(map) {
  mapLayerSafeRemove(map, kSelectedRouteLayerId);
  mapSourceSafeRemove(map, kSelectedRouteLayerId);
}

export function mapPushVectorSegments(
  map: mapboxgl.Map,
  url_subdirectory,
  layerDefinition,
  min_zoom,
  max_zoom,
  arrowLayerDefinition = undefined,
  hoverLayerDefinition = undefined,
) {
  const url = `${window.location.protocol}//${window.location.hostname}:${window.location.port}/${url_subdirectory}/{z}/{x}/{y}/`;
  const oldSource = map.getSource(kSegmentSourceId);
  // Prevent redraws by checking if the url will change or not
  if (oldSource) {
    // eslint-disable-next-line
    if ((oldSource as any)._options.tiles[0] === url) {
      console.debug('Source is identical, not redrawing');
      return;
    }
  }

  mapLayerSafeRemove(map, kSegmentLayerId);
  mapLayerSafeRemove(map, kSegmentSymbolsLayerId);
  if (hoverLayerDefinition) {
    mapLayerSafeRemove(map, hoverLayerDefinition.id);
  }
  mapSourceSafeRemove(map, kSegmentSourceId);
  // Url will change
  map.addSource(kSegmentSourceId, {
    type: 'vector',
    tiles: [url],
    minzoom: min_zoom,
    maxzoom: max_zoom,
    promoteId: 'base_segment_id',
  });
  map.addLayer({
    ...layerDefinition,
    'source-layer': kSegmentLayerSourceLayerId,
  } as mapboxgl.AnyLayer);
  if (arrowLayerDefinition) {
    map.addLayer(arrowLayerDefinition);
  }
  if (hoverLayerDefinition) {
    map.addLayer(hoverLayerDefinition, layerDefinition.id);
  }
}

export function searchMapForSegmentId(
  map: mapboxgl.Map,
  segment_id: string,
  layerId = undefined,
) {
  let finalLayerId = layerId;
  if (!layerId) {
    finalLayerId = kSegmentSourceId;
  }
  const params = {
    filter: ['==', ['get', 'segment_id'], segment_id],
    validate: false,
  };
  if (!layerId || layerId === kSegmentSourceId) {
    (params as any).sourceLayer = kSegmentLayerSourceLayerId;
  }

  const segments = map.querySourceFeatures(finalLayerId, params);
  return segments;
}

export function redrawBaseSegments(
  mapReady,
  map,
  layerDefinition,
  url_subdirectory,
  arrowLayerDefinition = undefined,
  hoverLayerDefinition = undefined,
  min_zoom = 10,
  max_zoom = 16,
) {
  if (mapReady) {
    if (mapShowingSelectedSegment(map)) mapPopSelectedSegment(map);
    if (url_subdirectory) {
      mapPushVectorSegments(
        map,
        url_subdirectory,
        layerDefinition,
        min_zoom,
        max_zoom,
        arrowLayerDefinition,
        hoverLayerDefinition,
      );
    }
  }
}

export function getGeoJSONLayerData(map, sourceId) {
  const source = map.getSource(sourceId);
  if (source) {
    return source?._options?.data; // eslint-disable-line
  }
  return undefined;
}

export function searchGeoJSONLayerForID(map, sourceId, segmentId) {
  const sourceData = getGeoJSONLayerData(map, sourceId);
  if (sourceData) {
    if (sourceData.properties?.segment_id === segmentId) {
      // eslint-disable-line
      return sourceData; // eslint-disable-line
    }
  }
  return undefined;
}

export function redrawSelectedSegment(
  mapReady,
  map,
  selectedSegmentId,
  routing,
  selectedRoute,
  force = false,
) {
  if (selectedSegmentId) {
    const segDrawn = searchGeoJSONLayerForID(
      map,
      kSelectedSegmentLayerId,
      selectedSegmentId,
    );
    const mapSegment = mergeSegments(
      searchMapForSegmentId(map, selectedSegmentId),
    );
    if (
      // Have we not already drawn the segment?
      !segDrawn || // Have we not already drawn this ID?
      force || // Are we forcing a redraw?
      (mapSegment &&
        !deepEquals(
          geojsonCoordinates(segDrawn),
          geojsonCoordinates(mapSegment),
        )) // Is the old segment out of date because new tiles have loaded?
    ) {
      if (mapSegment) {
        if (mapShowingSelectedSegment(map)) {
          mapPopSelectedSegment(map);
        }
        mapPushSelectedSegment(map, mapSegment);
      }
    }
  } else if (mapShowingSelectedSegment(map)) {
    mapPopSelectedSegment(map);
  }
  if (selectedRoute) {
    const routeSegmentIds = getRouteForSparseSegments(
      routing as LayerRouting,
      selectedRoute,
    );
    const newRouteGeom = makeSnakeFromIDs(routeSegmentIds, map);
    let needsRedraw = false;
    if (mapShowingRouteSegments(map)) {
      const existingRoute = getGeoJSONLayerData(map, kSelectedRouteLayerId);
      // Check if we've previously actually drawn all the route segments
      // Or if this try at a re-draw gets us more segments - in which case we want to draw it!
      // This is because, depending on zoom and tile loads, we don't neccsiarily have all of the
      // segments already on the map to query
      let needsPop = false;
      const drawn_ids = existingRoute?.properties?.segment_ids;
      const new_draw_ids = newRouteGeom?.properties?.segment_ids;
      const drawn_ids_length = (drawn_ids || '').split(',').length;
      const new_draw_ids_length = (new_draw_ids || '').split(',').length;
      if (!existingRoute || force) {
        needsPop = true;
      } else if (
        !drawn_ids ||
        !new_draw_ids || // If there's any nulls
        drawn_ids_length < new_draw_ids_length || // Only redraw if there are more segments available
        (drawn_ids_length === new_draw_ids_length && drawn_ids !== new_draw_ids) // Or if they're the same number but a different group
      ) {
        needsPop = true;
      }
      if (needsPop) {
        mapPopRouteSegments(map);
        needsRedraw = true;
      }
    } else {
      needsRedraw = true;
    }
    if (needsRedraw) {
      mapPushRouteSelect(newRouteGeom, map);
    }
  } else if (mapShowingRouteSegments(map)) {
    mapPopRouteSegments(map);
  }
  // else if (!needsCrosswalk(layer, selectedSegmentId, undefined)) {
  //   // e.g. previously selected segment not in this layer
  //   console.log('Selected segment doesn\'t exist and doesn\'t need a crosswalk, deselecting');
  //   dispatch(setSelectedSegmentId(undefined));
  // }
}

export function getSelectionFromClickEvent(
  event,
  map,
  refRouting,
  routeSelectionEnabled,
) {
  const isCtrlClick = event.originalEvent.ctrlKey;
  const currentWorkflowState = getWorkflowState(
    (store.getState() as any)?.workflow,
  );

  let returnedRoute;
  const maxSelectDistance = isCtrlClick
    ? kMaxScreenDistanceForRouteSelect
    : kMaxScreenDistanceForSelect;
  const clickBbox = [
    [event.point.x - maxSelectDistance, event.point.y - maxSelectDistance],
    [event.point.x + maxSelectDistance, event.point.y + maxSelectDistance],
  ] as [mapboxgl.PointLike, mapboxgl.PointLike];

  const segments = map.queryRenderedFeatures(clickBbox, {
    layers: [kSegmentLayerId],
  });

  const { nearestSegment, usedExclude: isSecondClick } =
    findNearestSegmentOnMap(
      segments,
      event.lngLat,
      maxSelectDistance,
      map,
      currentWorkflowState.segmentId,
    );
  // console.log(`### selected segment: ${JSON.stringify(nearestSegment)}`);

  // remove existing selection from map (if any)

  // set new nearestSegmentId (or undefined if none) in DOM ref and in global persisted state
  const nearestSegmentId =
    nearestSegment && nearestSegment.properties.segment_id;
  // console.log('Selecting ', nearestSegmentId);

  if (isCtrlClick && routeSelectionEnabled) {
    if (nearestSegmentId && refRouting.current) {
      let previouslySelectedSegment = currentWorkflowState.segmentId;
      let selectedRouteLocal = [
        ...(currentWorkflowState.routeSelection || [previouslySelectedSegment]),
      ];
      if (isSecondClick && selectedRouteLocal.length > 1) {
        // If we're switching to the second-closest segment, reroute rather than add more route
        // Reverse engineer whether it was prepended or appended
        const rindex = selectedRouteLocal.indexOf(previouslySelectedSegment);
        // console.debug('Popping previous segment due to second-click', rindex);
        if (rindex === 0) {
          selectedRouteLocal = selectedRouteLocal.slice(
            1,
            selectedRouteLocal.length - 1,
          );
          // eslint-disable-next-line
          previouslySelectedSegment = selectedRouteLocal[0];
        } else if (rindex === selectedRouteLocal.length - 1) {
          selectedRouteLocal.pop();
          previouslySelectedSegment =
            selectedRouteLocal[selectedRouteLocal.length - 1];
        }
      }
      // console.log(previouslySelectedSegment, 'to', nearestSegmentId);

      // Try forward routing
      const fwRouting = getRoutingForSegmentPair(
        refRouting.current as LayerRouting,
        previouslySelectedSegment,
        nearestSegmentId,
      );
      const fwCost = getRouteCost(
        refRouting.current as LayerRouting,
        fwRouting,
      );
      // Try reverse routing
      const bwRouting = getRoutingForSegmentPair(
        refRouting.current as LayerRouting,
        nearestSegmentId,
        previouslySelectedSegment,
      );
      const bwCost = getRouteCost(
        refRouting.current as LayerRouting,
        bwRouting,
      );

      let useReverse = false;
      if (bwCost < fwCost || (bwCost && !fwCost)) {
        useReverse = true;
        console.debug('Detected reverse routing!');
      }

      const routing = useReverse ? bwRouting : fwRouting;

      // console.debug('route Selection', selectedRouteLocal, 'new routing', routing);
      const newSparseRoute = [...selectedRouteLocal];
      if (routing) {
        if (!useReverse) {
          newSparseRoute.push(nearestSegmentId);
        } else {
          // console.debug('Inserting new selection to start of route, pre=', newSparseRoute);
          newSparseRoute.unshift(nearestSegmentId);
          // console.debug('Post: ', newSparseRoute);
        }
      } else if (selectedRouteLocal.length === 0) {
        // If routing hasn't worked, wipe the inferred route start
        selectedRouteLocal.splice(0, selectedRouteLocal.length);
      }
      console.debug('New sparse route', newSparseRoute);
      returnedRoute = newSparseRoute.filter((v) => v); // Filter out nulls and undefined
    } else {
      returnedRoute = null;
    }
  } else {
    returnedRoute = null;
  }
  return {
    segmentId: nearestSegmentId,
    segment: nearestSegment,
    route: returnedRoute,
  };
}

export function getInitialCenter(userProject) {
  const { workflow } = getState();
  const currentMapCenter = getProjectState(workflow)?.mapCenter;
  if (userProject || currentMapCenter) {
    // console.log(`============= useEffect -  initialize map`);

    // do this here once and for all since they won't change exogenously even if date changes
    // console.log('Project defaults', userProject);
    const initCenter = geojsonToCoordinates(userProject?.project_center);
    const center = currentMapCenter || initCenter;
    const zoom = getProjectState(workflow)?.mapZoom || initZoom;
    return { center, zoom };
  }
  return { center: undefined, zoom: undefined };
}

export function safeRemoveBookmarks(map) {
  mapLayerSafeRemove(map, kBookmarkLayerId);
  mapSourceSafeRemove(map, kBookmarkLayerId);
}

export function showBookmarks(map: mapboxgl.Map, bookmarks) {
  if (mapHasLayer(map, kBookmarkLayerId)) {
    safeRemoveBookmarks(map);
  }
  map.addSource(kBookmarkLayerId, {
    type: 'geojson',
    data: bookmarks,
  });
  map.addLayer(kBookmarkLayerDefinition);
}

export function updateMapLocation(
  map?: mapboxgl.Map,
  mapZoom?: number,
  mapCenter?: PtLatLng,
) {
  if (map) {
    const mapSet: mapboxgl.CameraOptions = {};
    if (mapCenter) {
      const moveMargin = 0.005; // Degrees
      const center = {
        lng: map.getCenter().lng,
        lat: map.getCenter().lat,
      };
      if (
        Math.abs(center.lng - mapCenter.lng) > moveMargin ||
        Math.abs(center.lat - mapCenter.lat) > moveMargin
      ) {
        mapSet.center = mapCenter;
      }
    }
    if (mapZoom) {
      const zoomMargin = 0.1;
      const currentZoom = map.getZoom();
      if (Math.abs(currentZoom - mapZoom) > zoomMargin) {
        mapSet.zoom = mapZoom;
      }
    }
    if (Object.keys(mapSet).length > 0) {
      map.jumpTo(mapSet);
    }
  }
}

export function MapLoadingSpinner({
  currentRefMap,
  mapReady,
}: {
  currentRefMap: RefObject<mapboxgl.Map>;
  mapReady: boolean;
}): ReactElement {
  const [showMapLoading, setShowMapLoading] = useState(true);

  useEffect(() => {
    if (!currentRefMap.current) {
      return;
    }

    const LOADING_EVENT = 'dataloading';
    const LOADED_EVENT = 'idle';

    currentRefMap.current.on(LOADING_EVENT, () => {
      setShowMapLoading(true);
    });

    currentRefMap.current.on(LOADED_EVENT, () => {
      setShowMapLoading(false);
    });

    if (currentRefMap.current.loaded()) {
      setShowMapLoading(false);
    }
  }, [mapReady]); // TODO OS-337 remove dependence on mapReady, pass map as state instead of ref

  return showMapLoading && <BottomLeftFloatingLoadingSpinner />;
}
