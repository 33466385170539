/* eslint-disable max-classes-per-file */
import {
  LngLatArrayType,
  PtXy,
  PtLatLng,
  Segment,
  LayerRouting,
} from 'appTypes';
import mapboxgl from 'mapbox-gl';
import { Dijkstra } from './dijkstra';
import { GeoJSON_Point } from '../../state/userSlice';
import { kSegmentLayerSourceLayerId, kSegmentSourceId } from './mapCommon';
import { arrayEquals } from '../common/utils';

export function jsonTupeToLatlng(jt: LngLatArrayType) {
  return { lng: jt[0], lat: jt[1] };
}

export function latLngToJsonPoint(ll: PtLatLng): GeoJSON_Point {
  return { type: 'Point', coordinates: [ll.lng, ll.lat] };
}

export function jsonPointToLatLng(jp: GeoJSON_Point) {
  return jsonTupeToLatlng(jp.coordinates as any);
}

export function launderLatLng(ll: PtLatLng): PtLatLng {
  return { lng: Number(ll.lng), lat: Number(ll.lat) };
}

function beLatLng(pt: PtXy): PtLatLng {
  if ('x' in pt && 'y' in pt) return { lng: pt.x, lat: pt.y };
  return pt as unknown as PtLatLng;
}

export function geojsonToCoordinates(point?: GeoJSON_Point): PtLatLng {
  if (point) {
    return { lat: point.coordinates[1], lng: point.coordinates[0] };
  }
  return undefined;
}

function distanceSquaredLatLng(v: PtLatLng, w: PtLatLng) {
  return (v.lng - w.lng) ** 2 + (v.lat - w.lat) ** 2;
}

function distanceLatLng(v: PtLatLng, w: PtLatLng) {
  return Math.sqrt(distanceSquaredLatLng(v, w));
}

function distanceXy(v: PtXy, w: PtXy) {
  return Math.sqrt(distanceSquaredLatLng(beLatLng(v), beLatLng(w)));
}

export function searchSegmentsForSegmentId(
  segments: Segment[],
  segment_id: string,
): Segment {
  return segments.find(
    (segment) => String(segment.properties.segment_id) === segment_id,
  );
}

function nearestPointOnLineSegmentLatLng(
  p: PtLatLng,
  a: PtLatLng,
  b: PtLatLng,
) {
  // find closest point on segment ab to point p (all points xy)
  const segmentLengthSquared = distanceSquaredLatLng(a, b);
  if (segmentLengthSquared === 0) return a;
  let t =
    ((p.lng - a.lng) * (b.lng - a.lng) + (p.lat - a.lat) * (b.lat - a.lat)) /
    segmentLengthSquared;
  t = Math.max(0, Math.min(1, t));
  return {
    lng: a.lng + t * (b.lng - a.lng),
    lat: a.lat + t * (b.lat - a.lat),
  };
}

function distanceToSegmentLatLng(segment: Segment, pt: PtLatLng) {
  const { coordinates } = segment.geometry;

  let segmentDist: number;
  let segmentPt: PtLatLng;

  // for each leg in segment
  for (let j = 0; j < coordinates.length - 1; j++) {
    const legPt = nearestPointOnLineSegmentLatLng(
      pt,
      jsonTupeToLatlng(coordinates[j]),
      jsonTupeToLatlng(coordinates[j + 1]),
    );
    const legDist = distanceLatLng(legPt, pt);
    if (!segmentDist || segmentDist > legDist) {
      segmentDist = legDist;
      segmentPt = legPt;
    }
  }

  return { segmentPt, segmentDist };
}

export function findNearestSegmentLatLng(
  segments: Segment[],
  position: PtLatLng,
  excludeId: string = undefined,
) {
  // all coordinates latlng
  // console.log(`findNearestSegmentLatLng position: ${JSON.stringify(position)}`);
  // console.log(`findNearestSegmentLatLng ${segments.length} segments`);

  let nearestSegment: Segment;
  let nearestSegmentPointLatLng: PtLatLng;
  let nearestSegmentDistance: number = Number.POSITIVE_INFINITY;
  // for each segment in segments
  for (let i = 0; i < segments.length; i++) {
    const segment = segments[i];
    if (!(excludeId && segment.properties.segment_id === excludeId)) {
      const { segmentPt, segmentDist } = distanceToSegmentLatLng(
        segments[i],
        position,
      );
      // if this is the closest segment we have seen so far, remember it
      if (!nearestSegment || nearestSegmentDistance > segmentDist) {
        nearestSegment = segment;
        nearestSegmentPointLatLng = segmentPt;
        nearestSegmentDistance = segmentDist;
      }
    }
  }

  return { nearestSegment, nearestSegmentPointLatLng };
}

export function findNearestSegmentOnMap(
  segments: Segment[],
  position: PtLatLng,
  maxClickDistance: number,
  map,
  excludeId: string = undefined,
): { nearestSegment: Segment; usedExclude: boolean } {
  // eslint-disable-next-line prefer-const
  let { nearestSegment, nearestSegmentPointLatLng } = findNearestSegmentLatLng(
    segments,
    position,
  );
  let usedExclude = false;
  if (nearestSegment && nearestSegment.properties.segment_id === excludeId) {
    usedExclude = true;
    const replacementMatch = findNearestSegmentLatLng(
      segments,
      position,
      excludeId,
    );
    nearestSegment = replacementMatch.nearestSegment;
    nearestSegmentPointLatLng = replacementMatch.nearestSegmentPointLatLng;
  }
  // ! check nearest distance in screen coordinates
  if (nearestSegment !== undefined) {
    const nearestSegmentPtXy = map.project(nearestSegmentPointLatLng);
    const clickPtXy = map.project(position);
    const distXy = distanceXy(clickPtXy, nearestSegmentPtXy);
    if (distXy > maxClickDistance) {
      nearestSegment = undefined;
    }
  }

  return { nearestSegment, usedExclude };
}

export function geojsonCoordinates(segment) {
  if (segment.coordinates) {
    return segment.coordinates;
  }
  return segment.geometry.coordinates;
}

export function mergeSegments(segments) {
  if (!segments || segments.length === 0) {
    return undefined;
  }
  if (segments.length === 1) {
    return segments[0];
  }

  function pointEq(p1, p2) {
    const margin = 0.0005;
    if (Math.abs(p1[0] - p2[0]) < margin && Math.abs(p1[1] - p2[1]) < margin) {
      return true;
    }
    return false;
  }
  function excludingIndexes(list, i, j) {
    const ret = [];
    for (let it = 0; it < list.length; it++) {
      if (!(it === i || it === j)) {
        ret.push(list[i]);
      }
    }
    return ret;
  }
  let coords = [];

  for (let i = 0; i < segments.length; i++) {
    coords.push(geojsonCoordinates(segments[i]));
  }
  let progress = true;
  while (progress && coords.length > 1) {
    progress = false;
    for (let i = 0; i < coords.length; i++) {
      for (let j = i + 1; j < coords.length; j++) {
        if (pointEq(coords[i][0], coords[j][coords[j].length - 1])) {
          // J -> I
          // console.log('J -> I', i, j);
          const newCord = [...coords[j], ...coords[i]];
          coords = [newCord, ...excludingIndexes(coords, i, j)];
          progress = true;
        } else if (pointEq(coords[j][0], coords[i][coords[i].length - 1])) {
          // I -> J
          // console.log('I -> J', i, j);
          const newCord = [...coords[i], ...coords[j]];
          coords = [newCord, ...excludingIndexes(coords, i, j)];
          progress = true;
        } else if (arrayEquals(coords[i], coords[j])) {
          // I = J
          // console.log('I = J', i, j);
          coords = [...excludingIndexes(coords, j, j)];
          progress = true;
        }
      }
    }
  }
  const kingSegment = segments[0];
  const ret = {
    type: kingSegment.type,
    properties: kingSegment.properties,
    geometry: kingSegment.geometry,
    coordinates: kingSegment.coordinates,
  };
  if (kingSegment.coordinates) {
    // eslint-disable-next-line
    ret.coordinates = coords[0];
  } else {
    // eslint-disable-next-line
    ret.geometry.coordinates = coords[0];
  }
  // console.log('Merged segments ', segments, 'to', ret);
  return ret;
}

function selectorFromId(id: string) {
  if (id.startsWith('#')) {
    return id;
  } else {
    return `#${id}`;
  }
}

export function getLayerToggleVisibility(widgetSelectorId: string) {
  const element = document.querySelector(selectorFromId(widgetSelectorId));
  if (element) {
    return element.classList.contains('enabled');
  } else {
    return undefined;
  }
}

export function setLayerToggleVisibility(
  visible: boolean,
  widgetSelectorId: string,
  segmentLayerId: string,
  map: mapboxgl.Map,
) {
  const element = document.querySelector(selectorFromId(widgetSelectorId));
  if (visible) {
    element.classList.add('enabled');
    map.setLayoutProperty(segmentLayerId, 'visibility', 'visible');
  } else {
    element.classList.remove('enabled');
    map.setLayoutProperty(segmentLayerId, 'visibility', 'none');
  }
}

export function toggleLayerVisibility(
  widgetSelectorId: string,
  segmentLayerId: string,
  map: mapboxgl.Map,
) {
  setLayerToggleVisibility(
    !getLayerToggleVisibility(widgetSelectorId),
    widgetSelectorId,
    segmentLayerId,
    map,
  );
}

export function enableChartButton(widgetSelectorId: string, inactive: boolean) {
  // console.log(`enableChartButton current maximize: ${maximize} widgetSelectorId ${widgetSelectorId}`);
  const element = document.querySelector(widgetSelectorId);
  if (inactive && element.classList.contains('enabled')) {
    element.classList.remove('enabled');
  } else if (!inactive && !element.classList.contains('enabled')) {
    element.classList.add('enabled');
  }
}

export function getRouteCost(routing: LayerRouting, path: string[]) {
  if (!path) {
    return undefined;
  }
  let lastSeg = path[0];
  let cost = 0;
  for (let i = 1; i < path.length; i++) {
    cost += routing.forward[lastSeg][path[i]];
    lastSeg = path[i];
  }
  return cost;
}

export function getRoutingForSegmentPair(
  routing: LayerRouting,
  seg1: string,
  seg2: string,
) {
  if (!routing) return undefined;
  if (!(seg1 in routing.forward)) {
    console.error('Segment not in forward map');
  } else {
    // console.log(routing.forward[seg1]);
  }
  try {
    const path = new Dijkstra(routing.forward).find_path(seg1, seg2);
    return path;
  } catch (e) {
    return undefined;
  }
}

export function getRouteForSparseSegments(
  routing: LayerRouting,
  segments: Array<string>,
): Array<string> {
  if (!segments) {
    return [];
  }
  const route = [];
  for (let i = 1; i < segments.length; i++) {
    let trouting = getRoutingForSegmentPair(
      routing,
      segments[i - 1],
      segments[i],
    );
    if (trouting) {
      trouting = trouting.filter(
        (item) => routing !== null || routing !== undefined,
      );
      route.push(...trouting);
    }
  }
  return route;
}

export class ToggleLayerControl {
  // #map: any;
  #container: any;
  #widgetId: string;
  title?: string;
  onByDefault?: boolean;
  clickHandler?: (ev) => void;

  constructor(
    widgetId: string,
    title?: string,
    onByDefault?: boolean,
    clickHandler?: (ev) => void,
  ) {
    this.#widgetId = widgetId;
    this.title = title;
    this.onByDefault = onByDefault;
    if (this.onByDefault === undefined) {
      this.onByDefault = true;
    }
    this.clickHandler = clickHandler;
  }

  onAdd(map) {
    // console.log('ToggleLayerControl onAdd');
    // this.#map = map;
    this.#container = document.createElement('div');
    this.#container.className = 'showhide mapboxgl-ctrl mapboxgl-ctrl-group';
    const title = this.title ? `title="${this.title}"` : '';
    this.#container.innerHTML = `<button id="${this.#widgetId}" class="${this.onByDefault ? 'enabled' : ''}"><span class="mapboxgl-ctrl-icon" ${title}></span></button>`;
    if (this.clickHandler) {
      this.#container.addEventListener('click', this.clickHandler);
    }
    return this.#container;
  }

  onRemove() {
    // console.log('ToggleLayerControl onRemove');
    this.#container.parentNode.removeChild(this.#container);
    // this.#map = undefined;
  }
}

export class SettingsControl {
  // #map: any;
  #container: any;
  #widgetId: string;
  title?: string;
  handler;

  constructor(widgetId: string, handler, title?: string) {
    this.#widgetId = widgetId;
    this.title = title;
    this.handler = handler;
  }

  onAdd(map) {
    this.#container = document.createElement('div');
    this.#container.className = 'showhide mapboxgl-ctrl mapboxgl-ctrl-group';
    const title = this.title ? `title="${this.title}"` : '';
    this.#container.innerHTML = `<button id="${this.#widgetId}" class="enabled"><span class="mapboxgl-ctrl-icon" ${title}></span></button>`;
    this.#container.addEventListener('click', this.handler);
    return this.#container;
  }

  onRemove() {
    this.#container.parentNode.removeChild(this.#container);
  }
}

export function ensureRtlLoaded() {
  // Set up RTL text plugin, with silent failure
  try {
    if (['unavailable', 'error'].includes(mapboxgl.getRTLTextPluginStatus())) {
      mapboxgl.setRTLTextPlugin(
        'https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-rtl-text/v0.2.3/mapbox-gl-rtl-text.js',
        (err) => {
          if (err) {
            console.error(err);
          }
        },
      );
    }
  } catch (e) {
    console.error('Failed to load rtl plugin: ', e);
  }
}

export function makeSnakeFromIDs(routing, map) {
  if (!routing) return undefined;
  const segments = map.querySourceFeatures(kSegmentSourceId, {
    sourceLayer: kSegmentLayerSourceLayerId,
  });
  const coordinates = [];
  const renderedSegs = [];
  for (let i = 0; i < routing.length; i++) {
    if (i > 0 && String(routing[i]) === String(routing[i - 1])) {
      // eslint-disable-next-line no-continue
      continue;
    }
    const selectedSegment = searchSegmentsForSegmentId(
      segments,
      String(routing[i]),
    );

    if (selectedSegment) {
      renderedSegs.push(selectedSegment.properties.segment_id);
      const newCords = selectedSegment.geometry.coordinates;
      for (let j = 0; j < newCords.length; j++) {
        if (newCords[j] !== coordinates[coordinates.length - 1]) {
          coordinates.push(newCords[j]);
        }
      }
    }
  }
  const route = {
    type: 'Feature',
    geometry: {
      coordinates,
      type: 'LineString',
    },
    properties: {
      segment_ids: renderedSegs.join(','),
    },
  };
  if (renderedSegs.length > 0) {
    return route;
  } else {
    return undefined;
  }
}
